<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      light
      app
      elevation="1"
      elevate-on-scroll
      height="50"
      color="secondary"
      class="border"
    >
      <!--base-img
        :src="require('@/assets/logo.svg')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="46"
        width="100%"
      /-->

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
          primary
        >
          <v-tab
            v-for="(name, i) in items"
            :key="i"
            dark
            :to="{ name }"
            :exact="name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ name }}
          </v-tab>
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  import HomeDrawer from './Drawer'

  export default {
    name: 'HomeAppBar',
    components: {
      HomeDrawer,
    },

    data: () => ({
      drawer: null,
      items: [
        'Home',
        'Shows',
        'Team',
        'News',
        'Preise',
      ],
    }),
  }
</script>

<style>
.mdi-menu::before {
  color: #FF2E2E !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active){
  color: #FF2E2E !important
}
.theme--light.v-tabs > .v-tabs-bar .v-tab.v-tab--active{
  color: #991B1B !important
}
.v-tabs-slider{
  color: transparent !important
}
.border{
  border-bottom: 1px solid!important;
  border-bottom-color: red!important;
}
.v-application .secondary{
  border-color:#E52929!important;
}
.v-list-item__title{
  font-weight: bold!important;
}
</style>
